import { z } from 'zod';

const dekSchema = z.object({
    str: z.string(),
    value: z.any(),
});

const imageSchema = z.object({
    id: z.string(),
    altText: z.string().nullable().optional(),
    width: z.number().nullable(),
    height: z.number().nullable(),
    url: z.string().nullable(),
    mimeType: z.string().nullable(),
    caption: dekSchema.nullable(),
});

const mediaSourceSchema = z.object({
    url: z.string().nullable(),
    label: z.string().nullable(),
});

const videoPosterSchema = z.object({
    id: z.string(),
    altText: z.string().nullable().optional(),
    width: z.number().nullable(),
    height: z.number().nullable(),
    url: z.string().nullable(),
    mimeType: z.string().nullable(),
    caption: dekSchema.nullable(),
});

const avatarSchema = z.object({
    id: z.string(),
    altText: z.string().nullable().optional(),
    width: z.number().nullable(),
    height: z.number().nullable(),
    url: z.string().nullable(),
});

const peopleSchema = z.object({
    center: z.array(z.string()).nullable(),
    personDetails: z.object({
        personType: z.array(z.string()).nullable(),
    }).nullable(),
    personTitle: z.string().nullable(),
    avatar: avatarSchema.nullable(),
    isArchived: z.boolean().nullable(),
    //featuredTopics
    //featuredRegions
    firstName: z.string().nullable(),
    middleName: z.string().nullable(),
    lastName: z.string().nullable(),
    suffix: z.string().nullable(),
    hasProfile: z.boolean().nullable(),
});

const blogsSchema = z.object({
    id: z.string(),
    title: z.string(),
    url: z.string().nullable(),
});

const syndicatedSchema = z.object({
    blogs: z.array(blogsSchema),
    centers: z.record(z.string(), z.object({
        syndicated: z.boolean(),
        isPrimary: z.boolean(),
    }))
});

const contentIndexSchema = z.object({
    id: z.string(),
    collection: z.string(),
    status: z.string().nullable(),

    label: z.string(),
    url: z.string().nullable(),
    publishedAt: z.number().nullable(),
    title: z.string(),
    dek: dekSchema.nullable(),
    contributors: z.array(peopleSchema).nullable(),
    mainAuthors: z.array(peopleSchema).nullable(),
    image: imageSchema.nullable(),
    images: z.array(imageSchema).nullable(),
    publisherName: z.string().nullable(),
    mediaSource: mediaSourceSchema.nullable(),
    videoPoster: videoPosterSchema.nullable(),

    dateTime: z.object({
        startTimestamp: z.number().nullable(),
        endTimestamp: z.number().nullable(),
        start: z.string().nullable(),
        end: z.string().nullable(),
        timezone: z.string().nullable(),
    }).nullable().optional(),
    
    year: z.number().nullable(),
    syndications: syndicatedSchema.nullable(),
});

export type ContentIndex = z.infer<typeof contentIndexSchema>;

export const schema = contentIndexSchema;

export const parse = (data: any): ContentIndex => schema.parse(data)