import { parse } from "@/types/contentIndex";

const transformContentSearchIndexToContentCardBlockProps = (result: any): any => {
    const content = parse(result);
    const syndicatedBlogs = content.syndications?.blogs?.map(blog => ({ path: blog.url, title: blog.title })) || [];
    const syndicatedCenters = 
        Object.keys(content.syndications?.centers || {})
            .reduce((acc, centerId) => {
                const center = content.syndications?.centers[centerId];
                if (!center) return acc;
                acc[centerId] = {
                    isPrimary: center.isPrimary,
                    syndicated: center.syndicated,
                };
                return acc;
            }, {} as Record<string, { isPrimary: boolean, syndicated: boolean }>);
    return {
        title: content.title,
        label: content.label,
        url: content.url,
        contentType: content.collection,
        image: content.image,
        images: content.images,
        description: content.dek?.value,
        mediaSource: content.mediaSource,
        people: content.mainAuthors || content.contributors,
        publisherName: content.publisherName,
        publishedAt: content.publishedAt ? new Date(content.publishedAt).toISOString() : null,
        videoPoster: content.videoPoster,
        summary: {
            syndications: {
                blogs: syndicatedBlogs,
                centers: syndicatedCenters,
            } 
        },
        dateTime: content.dateTime,
    }
}

export default transformContentSearchIndexToContentCardBlockProps;
